
.works__work {
    padding-top: 20px;
    padding-bottom: 20px;
}

.works__work_container h3 {
    margin-top: 0;
}

.works__work_container .row {
    padding-top: 25px;
}

.works__work_container .col {
    position: relative;
}

.works__work_image_container {
    position: relative;
    margin-bottom: calc(var(--bs-gutter-x));
}

.video_rounded video {
    margin-bottom: -5px;
}

.works__work_container {
    margin-top: 6rem;
}

.works__work_container:nth-child(2) {
    margin-top: 0;
}

@media (min-width: 1400px) {
    .media_rounded {
        border-radius: 27px;
    }
}
@media (max-width: 767px) {
    .media_rounded {
        border-radius: 16px;
    }
}
@media (min-width: 768px) {
    .media_rounded {
        border-radius: 11px;
    }
}
@media (min-width: 992px) {
    .media_rounded {
        border-radius: 22px;
    }
}
@media (min-width: 1200px) {
    .media_rounded {
        border-radius: 25px;
    }
}
@media (max-width: 500px) {
    .media_rounded {
        border-radius: 13px;
    }
}
