.hero {
  height: calc(100vh - 44px);
  overflow: hidden;
}

.hero__img {
  width: auto;
  height: 70vh;
  position: 'relative';
  object-fit: cover;
  overflow: hidden;
}

.hero__txt {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero__txt h1 {
  font-weight: 300;
  font-size: calc(1.5rem + 2.5vw);
  margin-left: 1rem;
  margin-bottom: 0;
}

.hero__img_credits_container {
  height: 70vh;
  width: fit-content;
  margin-right: auto;
  position: relative;
  margin-left: 50%;
  transform: translateX(-50%);
}

.hero__img__credits {
  font-size: 0.7rem;
  position: absolute;
  bottom: 0;
  max-width: 100vw;
  width: 100%;
  text-align: right;
  padding-right: 5px;
  margin-left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 991px) {}

/*style={{width: 'auto', height: 'auto', position: 'relative'}}*/

.hero__img img {
    animation: fadeIn 1s;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }