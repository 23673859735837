.videoplayer__player_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background: hsla(0,0%,100%,.2);
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
}

.videoplayer__player_wrapper {
    width: 94%;
    max-width: 1920px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    padding: 56.25% 0px 0px;
    position: relative;
}

.videoplayer__react_player {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
    overflow: hidden;
}


.videoplayer_play_button_container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.videoplayer_play_button {
    color: #fff;
    background-color: rgba(96,96,96,.6);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    transition: background-color .15s cubic-bezier(.165,.84,.44,1) 0ms,color .15s cubic-bezier(.165,.84,.44,1) 0ms;
    width: 5em;
    border-radius: 1em;
    background-image: url(/play-button.svg);
    aspect-ratio: 179/130;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    outline: none;
    border: none;
}

.videoplayer_play_button:active,.videoplayer_play_button:hover {
    color: #fff;
    background-color: hsla(0,0%,69%,.6)
}