
.connect {
    background-color: #1d1d1f;
    color: #fff;
}

.connect h2, .connect h3, .connect a {
    color: #fff !important;
}

.connect__contact {
    display: flex;
    justify-content: center;
    align-items: center;
}

.connect__contact_container {
    height: calc(100vh - 4rem - 1.7vw - 25px);
    align-items: center;
}

@media (max-width: 768px) {
    .connect__contact_container {
        text-align: right;
    }
    .connect__contact {
        display: block;
    }
    .connect__contact:first-child {
        margin-top: auto;
    }
    .connect__contact:last-child {
        margin-bottom: auto;
    }

}


.connect p {
    margin-bottom: 2rem;
}
