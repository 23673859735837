
.services {
    margin-bottom: 70px;
}

.services p {
    margin-bottom: 1.7rem;
}
.services__subtext_container {
    display: flex;
    align-items: center;
    margin-bottom: 6rem;
}

.services__subtext h4 {
    position: sticky;
    top: 1rem;
}

.services__approach_operatingmodel_container {
    display: flex;
    justify-content: center;        
    margin-bottom: 6rem;
}

.services__head__txt {
    padding-bottom: 5px;
}

.services__approach_operatingmodel {
    position: relative;
    display: block;
}

.services__approach_operatingmodel span {
    position: absolute;
}

.services__approach_operatingmodel:hover span.hover {
    display: block;
    opacity:1;
}

.services__approach_text_top,
.services__approach_text_left,
.services__approach_text_right {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 61.2%;
    height: 61.2%;
    font-size: 12px;
    text-align: center !important;
}

.services__approach_text_left,
.services__approach_text_right {
    bottom: 0;
}

.services__approach_text_top {
    top: 0;
    transform: translateX(32%);
    padding-bottom: 40px;
}

.services__approach_text_left {
    left: 0;
    padding-top: 40px;
    padding-right: 60px;
}
.services__approach_text_right {
    right: 0;
    padding-top: 40px;
    padding-left: 60px;
}

.services__approach_operatingmodel:hover span,
.services__approach_text_top .hover,
.services__approach_text_left .hover,
.services__approach_text_right .hover {
    opacity:0;
    transition:0.3s;
}

.services__approach_img {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    max-width: 100%;
}

.services__head {
    position: relative;
}

.services__head__txt {
    color: #fff;
}